import AppNavigation from '@/components/UI/navigation/app'
import PropTypes from 'prop-types'
import Styled from './styles'
import { forwardRef } from 'react'
import { useCompany } from '@/utils'

export const StyledHeader = forwardRef(({ navOptions = {} }, ref) => {
  const { company } = useCompany()
  const logoSrc = company ? `/${company}-opex.svg` : ''

  return (
    <div ref={ref}>
      <Styled.Header>
        <Styled.Branding>
          <img src={logoSrc} alt="OPEX standard" />
        </Styled.Branding>
        <AppNavigation {...navOptions} />
      </Styled.Header>
    </div>
  )
})

StyledHeader.propTypes = {
  navOptions: PropTypes.object,
}

export default StyledHeader

import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import NavItem from 'react-bootstrap/NavItem'
import Dropdown from 'react-bootstrap/Dropdown'

// ui components
import UserMenu from '@/components/UI/userMenu'
import { CustomMenu, CustomToggle } from '@/components/UI/dropdown'
import Styled from './styles'

// utils
import { PROJECT_PHASES } from '@/utils/constants'
import { useAuthUser, useCompany } from '@/utils/hooks'

const setInitDropdownState = (navItems) => {
  return Object.assign(
    {},
    ...Object.entries(navItems).map(([prop]) => ({
      [prop]: false,
    }))
  )
}

const AppNavigation = ({ deselected = false }) => {
  // #region variables
  const router = useRouter()
  const { primed, user } = useAuthUser()
  const { company: currentCompany } = useCompany()
  const [dropdowns, setDropdowns] = useState({})
  // #endregion

  // #region functions
  const handleToggleStyles = (company, isOpen) => {
    setDropdowns((prev) => ({ ...prev, [company]: isOpen }))
  }

  const getBckGrdClr = (key) => {
    let color = ''
    switch (key) {
      case 'POINTCORE':
        color = '#6ca33c'
        break
      case 'CORE':
        color = '#008348'
        break
      case 'PROCEDEO':
        color = '#00395B'
        break
      default:
        color = '#fff'
    }
    return color
  }

  const navLinks = useMemo(() => {
    if (primed) {
      if (user?.superAdmin) {
        // filter out empty paths/links
        return Object.entries(PROJECT_PHASES).reduce(
          (acc, [company, links = []]) => {
            if (links?.length) acc[company] = links
            return acc
          },
          {}
        )
      } else {
        // build object that has user's access in a format easy to compare to project phases constant
        const access = user?.permissionTemplates?.reduce((acc, template) => {
          acc[template.company.id] = template?.permissions?.modules?.reduce(
            (acc, { name, permission }) => {
              if (permission !== 'NONE') acc.push(name.toLowerCase())
              return acc
            },
            []
          )
          return acc
        }, {})

        return Object.entries(PROJECT_PHASES).reduce(
          (acc, [company, links = []]) => {
            const filteredLinks = links?.filter(({ path }) =>
              access[company]?.includes(path.split('/').pop().toLowerCase())
            )
            if (filteredLinks?.length) acc[company] = filteredLinks
            return acc
          },
          {}
        )
      }
    } else {
      return {}
    }
  }, [primed, user])
  // #endregion

  // #region effects
  // set the initial state of the dropdowns after building the nav links for user
  useEffect(() => {
    setDropdowns(setInitDropdownState(navLinks))
  }, [navLinks])
  // #endregion

  return (
    <Styled.CompanyNav>
      {Object.keys(navLinks).map((key) => {
        const company = navLinks[key]
        const isOpen = dropdowns[key]
        const switchCondition =
          (key.toLowerCase() === currentCompany &&
            !isOpen &&
            !deselected &&
            // Check if we're on a settings page. If so, then we're not technically in a company's module
            !router.asPath.toLowerCase().includes('settings')) ||
          isOpen

        return (
          <Dropdown
            as={NavItem}
            key={key}
            className={`dropdown-nav-item ${isOpen ? 'open' : 'closed'}`}
            style={{
              ...(switchCondition
                ? { backgroundColor: getBckGrdClr(key) }
                : {}),
            }}
            onToggle={(open, event) => handleToggleStyles(key, open)}
          >
            <Dropdown.Toggle as={CustomToggle}>
              <Styled.NavItem
                className={`${switchCondition ? 'open' : 'closed'}`}
              >
                {key}
              </Styled.NavItem>
              <Styled.Icon
                icon={isOpen ? ['fal', 'angle-up'] : ['fal', 'angle-down']}
                className={`${switchCondition ? 'open' : 'closed'}`}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {company.map(({ link, path }) => (
                <Dropdown.Item
                  key={path}
                  className={'dropdown-item'}
                  onClick={() => router.push(path)}
                >
                  {link}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      })}
      <Styled.UserBadgeWrapper>
        <UserMenu />
      </Styled.UserBadgeWrapper>
    </Styled.CompanyNav>
  )
}

AppNavigation.propTypes = {
  deselected: PropTypes.bool,
}

export default AppNavigation
